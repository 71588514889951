import { Component, OnInit } from '@angular/core';
import { Tile } from '../tile';

@Component({
  selector: 'app-mountainbiking',
  templateUrl: './mountainbiking.component.html',
  styleUrls: ['./mountainbiking.component.sass']
})
export class MountainbikingComponent extends Tile implements OnInit {

  constructor() {
    super();
    this.label = "Mountainbiking";
    this.link = '/mountainbiking';
  }

  ngOnInit() {
  }

}
