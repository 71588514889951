export class Tile {
    private _label: string = "";
    get label(): string {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
    }

    private _link: string = "";
    get link(): string {
        return this._link;
    }
    set link(value: string) {
        this._link = value;
    }
}
