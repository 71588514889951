import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavigationPageComponent } from './navigation-page/navigation-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TilesPageComponent } from './tiles-page/tiles-page.component';
import { CurriculumVitaeComponent } from './curriculum-vitae/curriculum-vitae.component';
import { WebArchiveComponent } from './web-archive/web-archive.component';

import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

interface INavCat {
  Category: string;
  Main: string;
  Pages: [];
}

interface INavItem {
  Title: string;
  ContentURL: string;
}

interface IWebArchiveLink {
  Title: string;
  Year: string;
  Path: string;
  Location: string;
}

@Injectable({
  providedIn: 'root',
})
export class DynamicRouteLoader {

  navMenu: Array<INavCat>;

  constructor(
    private httpService: HttpClient,
    private router: Router,
  ) {
    routes.forEach((route) => {
      if (route.data != null) {
        this.registerRoutes(route.data.title);
      }
      return route;
    })
  }

  // TODO?: create a sub-component `PageContent` and assign (generated) content dynamically to it

  private registerPage(title: string, section: string, page: string) {
    const link: string = section + '/' + page;
    const baseHref = 'function/ui'; // TODO: get baseHref automatically from ...?
    // TODO: finish dynamic router configuration; needs updates on NavigationPageComponent...
    this.router.config.unshift(
      // TODO: remove .md from path/link...
      //{ path: '/' + baseHref + '/' + section + '/' + page, component: NavigationPageComponent, data: { title, contentURL: link } }
      { path: '/' + section + '/' + page, component: NavigationPageComponent, data: { title, contentURL: link } }
    );
  }

  private registerPage2(title: string, year: string, url: string, location: string) {
    this.router.config.unshift(
      { path: url, component: WebArchiveComponent, data: { title, year, url, location } }
    );
  }

  private registerRoutes(section: string) {
      // read menu data from JSON file via HTTP into the component
      // e.g. like decsribed here: https://angular.io/guide/http#making-a-request-for-json-data
      this.httpService.get('./assets/json/' + section + '.json').subscribe(
        data => {
          this.navMenu = data as Array<INavCat>; // fill the array with data

          // register dynamic pages in Angular router
          for (const navCat of this.navMenu) {
            for (const item of navCat.Pages) {
              const navItem: INavItem = item;
              let url = navItem.ContentURL;
              if (url.substr(url.length - 3, 3) === '.md') {
                url = url.substr(0, url.length - 3);
              }
              this.registerPage(navItem.Title, section.toLowerCase(), url);
            }
          }
        },
        (err: HttpErrorResponse) => {
          // TODO: not all sections have a sub-menu
          console.log(err.message);
        }
      );

      // TODO: check whether the two register route function can be combined
      this.httpService.get('./assets/json/WebArchive.json').subscribe(
        data => {
          let archive = data as Array<IWebArchiveLink>; // fill the array with data
          for (const web of archive) {
              this.registerPage2(web.Title, web.Year, web.Path, web.Location);
          };
        },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
  }
}

// TODO: (dynamic) routes should be generated from (json) data...
const routes: Routes = [
  {
    path: 'main',
    component: LandingPageComponent
  },
  {
    path: 'familyfathering',
    component: NavigationPageComponent,
    data: { title: 'Familyfathering', startURL: 'landfreude-club.md' },
    // loadChildren: () =>  } TODO: check wether "Lazy Loading", i.e. loadChildren() function could do the trick too...
  },
  {
    path: 'familyfathering/:pageFileName',
    component: NavigationPageComponent,
    data: { title: 'Familyfathering' }
  },
  {
    path: 'mountainbiking',
    component: NavigationPageComponent,
    data: { title: 'Mountainbiking', startURL: 'klosterberg-trails.md' }
  },
  {
    path: 'mountainbiking/:pageFileName',
    component: NavigationPageComponent,
    data: { title: 'Mountainbiking' }
  },
  {
    path: 'programming',
    component: NavigationPageComponent,
    data: { title: 'Programming', startURL: 'linux-router.md' }
  },
  {
    path: 'programming/:pageFileName',
    component: NavigationPageComponent,
    data: { title: 'Programming' }
  },
  {
    path: 'brothersdehaaki',
    component: TilesPageComponent,
    data: { title: 'Brothers de Haaki' }
  },
  {
    path: 'cv',
    component: CurriculumVitaeComponent,
    data: { title: 'Thomas Haak\'s Curriculum Vitae' }
  },
  {
    path: 'web-archive',
    component: WebArchiveComponent,
    data: { title: 'Web Archive', startURL: 'thomas.vakoon.de' }
  },
  {
    path: 'web-archive/:webFolder',
    component: WebArchiveComponent,
    data: { title: 'Web Archive' }
  },
  { path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  // TODO: make page robust in a way that it always redirects to the start page
  { path: '**', component: LandingPageComponent }

  // TODO: do we need a 404 page here...? Sure, we always do, but this only works after all other pages have been registered...
  /*{ path: '**', component: PageNotFoundComponent }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <--- debugging purposes only
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(
    private router: Router,
    private dynamicRouteLoader: DynamicRouteLoader,
  ) {
  }
}
