import { Component, OnInit } from '@angular/core';
import { Tile } from '../tile';

@Component({
  selector: 'app-programming',
  templateUrl: './programming.component.html',
  styleUrls: ['./programming.component.sass']
})
export class ProgrammingComponent extends Tile implements OnInit {

  constructor() {
    super();
    this.label = "Programming";
    this.link = '/programming';
  }

  ngOnInit() {
  }

}
