import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

interface IWebArchiveLink {
  Title: string;
  Year: string;
  Path: string;
  Location: string;
}

@Component({
  selector: 'app-web-archive',
  templateUrl: './web-archive.component.html',
  styleUrls: ['./web-archive.component.sass']
})
export class WebArchiveComponent implements OnInit {

  title = '';

  pageTitle: string;
  pageContent: string;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpClient,
    private router: Router,
    private location: Location,
    private sanitizer: DomSanitizer,
    ) { }

  private webFolder = '';

  archive: Array<IWebArchiveLink>;

  year = "";
  localWebSitePath;
  url;

  // TODO: combine this component with 'NavigationPageComponent'

  ngOnInit() {
    this.route.params
      .subscribe((params) => {
        this.webFolder = params.webFolder;
      });

    // receive 'title', etc. from Angular router data
    this.route.data
      .subscribe((data: { title: string, startURL: string }) => {

        this.title = data.title;
        
        if (!this.webFolder && data.startURL != '') {
          this.webFolder = data.startURL;
        }

        this.getYear(this.webFolder); // sets this.year...

        // TODO: this seems hacky...
        this.url = 'web-archive/' + this.webFolder;

        // TODO: this seems hacky...
        this.localWebSitePath = 'assets/web/' + this.webFolder + '/index.html';

        this.renderPage(this.title, this.year, this.url, this.localWebSitePath);
      });

    // TODO: check whether the two register route function can be combined
    this.httpService.get('./assets/json/WebArchive.json').subscribe(
      data => {
        this.archive = data as Array<IWebArchiveLink>; // fill the array with data
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  ngAfterViewInit() {

    // register events for the Navbar Burger Toggle

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active'); // TODO: this seems not to work,
          // but see below event handler for the ".navbar-menu"; this one worked...
        });
      });
    }

    const $navbarMenus = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu'), 0);

    // Check if there are any navbar menus
    if ($navbarMenus.length > 0) {

      // Add a click event on each of them
      $navbarMenus.forEach( el => {
        el.addEventListener('click', () => {

          // Toggle the "is-active" class on the "navbar-menu"
          el.classList.toggle('is-active');

          // Try to (back-)toggle the burger...
          const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

          // Check if there are any navbar burgers
          if ($navbarBurgers.length > 0) {

            // Add a click event on each of them
            $navbarBurgers.forEach( nb => {
              // Get the target from the "data-target" attribute
              if (nb.dataset.target == el.id) {
                // Toggle burger
                nb.classList.toggle('is-active');
              }
            });
          }
        });
      });
    }
  }

  getYear(webFolder: string) {
    // TODO: multiple get's on 'WebArchive.json' here seems hacky...
    this.httpService.get('./assets/json/WebArchive.json').subscribe(
      data => {
        let webArchive = data as Array<IWebArchiveLink>; // fill the array with data
        this.year = webArchive.find(item => item.Path == "/web-archive/" + webFolder,).Year;
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  renderPage(title: string, year: string, url: string, location: string) {
    this.pageTitle = title;
    this.year = year;

    // Need to make Angular trust my (local) URL...
    this.localWebSitePath = this.sanitizer.bypassSecurityTrustResourceUrl(location);
    this.location.replaceState(url);
  }
}
