import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaasInvokerService {

  constructor(private httpService: HttpClient) { }

  //response: string;

  invokeFunction(func: string, arg: string): Observable<string> {
    // this.httpService.post('http://79.143.185.67:31112/function/' + func, arg).subscribe(
    //return this.httpService.post('http://faas.haakts.net:8080/function/' + func, arg, { responseType: 'text' });
    return this.httpService.post('api/function/' + func, arg, { responseType: 'text' });
    /*
      .subscribe(
        data => {
          console.log(data);
          this.response = data as string;
        },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );
    return of(this.response);
    */
  }
}
