import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material';
import { MatToolbarModule } from '@angular/material';
import { MatIconModule } from '@angular/material';

import { FamilyfatheringComponent } from './familyfathering/familyfathering.component';
import { MountainbikingComponent } from './mountainbiking/mountainbiking.component';
import { ProgrammingComponent } from './programming/programming.component';
import { BrothersDeHaakiComponent } from './brothers-de-haaki/brothers-de-haaki.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CliComponent } from './cli/cli.component';
import { NavigationPageComponent } from './navigation-page/navigation-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PageContentComponent } from './page-content/page-content.component';

import { HttpClientModule } from '@angular/common/http';
import { TilesPageComponent } from './tiles-page/tiles-page.component';
import { CurriculumVitaeComponent } from './curriculum-vitae/curriculum-vitae.component';
import { WebArchiveComponent } from './web-archive/web-archive.component';

@NgModule({
  declarations: [
    AppComponent,
    FamilyfatheringComponent,
    MountainbikingComponent,
    ProgrammingComponent,
    BrothersDeHaakiComponent,
    ToolbarComponent,
    CliComponent,
    NavigationPageComponent,
    LandingPageComponent,
    PageContentComponent,
    TilesPageComponent,
    CurriculumVitaeComponent,
    WebArchiveComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    AppRoutingModule,
    MatGridListModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    HttpClientModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
