import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

interface ICV {
  Category: string;
  Items: [];
}

interface ICVItem {
  Time: string;
  Organisation: string;
  Location: string;
  Role: string;
  Details: [];
}

@Component({
  selector: 'app-curriculum-vitae',
  templateUrl: './curriculum-vitae.component.html',
  styleUrls: ['./curriculum-vitae.component.sass']
})
export class CurriculumVitaeComponent implements OnInit {

  title = '';

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpClient
  ) { }

  cv: Array<ICV>;

  ngOnInit() {
    // receive 'title' from Angular router data
    this.route.data
    .subscribe((data: { title: string }) => {
      this.title = data.title;
    });

    // read cv data from JSON file via HTTP into the component
    // e.g. like decsribed here: https://angular.io/guide/http#making-a-request-for-json-data
    this.httpService.get('./assets/json/CV.json').subscribe(
      data => {
        this.cv = data as Array<ICV>; // fill the array with data
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

}
