import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cli',
  templateUrl: './cli.component.html',
  styleUrls: ['./cli.component.sass']
})
export class CliComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
