import { Component, OnInit } from '@angular/core';
import { Tile } from '../tile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-familyfathering',
  templateUrl: './familyfathering.component.html',
  styleUrls: ['./familyfathering.component.sass']
})
export class FamilyfatheringComponent extends Tile implements OnInit {

  constructor(private router: Router) {
    super();
    this.label = 'Familyfathering';
    this.link = '/familyfathering';
  }

  ngOnInit() {
  }

  goPage() {
    this.router.navigate([this.link]);
  }
}
