import { Component, OnInit } from '@angular/core';
import { Tile } from '../tile';

@Component({
  selector: 'app-brothers-de-haaki',
  templateUrl: './brothers-de-haaki.component.html',
  styleUrls: ['./brothers-de-haaki.component.sass']
})
export class BrothersDeHaakiComponent extends Tile implements OnInit {

  constructor() {
    super();
    this.label = "Brothers de Haaki";
    this.link = '/brothersdehaaki';
  }

  ngOnInit() {
  }

}
