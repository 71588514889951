import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import { FaasInvokerService } from '../faas-invoker.service';
//  import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';

interface INavCat {
  Category: string;
  Main: string;
  Pages: [];
}

interface INavItem {
  Title: string;
  ContentURL: string;
}

@Component({
  selector: 'app-navigation-page',
  templateUrl: './navigation-page.component.html',
  styleUrls: ['./navigation-page.component.sass']
})
export class NavigationPageComponent implements OnInit {

  title = '';

  pageTitle: string;
  pageContent: string;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpClient,
    private router: Router,
    private location: Location,
    private faasInvoker: FaasInvokerService
    ) { }

  navMenu: Array<INavCat>;

  private pageFileName = '';

  // TODO: create a sub-component `PageContent` and assign (generated) content dynamically to it

  // TODO: function currently unused
  registerPage(title: string, link: string) {
    // TODO: finish dynamic router configuration; needs updates on NavigationPageComponent...
    // this.router.config.unshift(
    //   { path: link, component: NavigationPageComponent, data: { title, contentURL: link } }
    // );
  }

  ngOnInit() {
    this.route.params
      .subscribe((params) => {
        this.pageFileName = params.pageFileName;
      });

    // receive 'title' from Angular router data
    this.route.data
      .subscribe((data: { title: string, startURL: string }) => {
        this.title = data.title;

        // TODO: don't cut end chars of pageUrl while page reload/refresh...!!!
        let pageURL = data.startURL;
        if (pageURL === '' || pageURL === undefined) {
            pageURL = this.pageFileName;
        }

        if (pageURL !== '') {
          this.renderPage(this.title.toLowerCase(), 'title', pageURL);
        }
      });

    // read menu data from JSON file via HTTP into the component
    // e.g. like decsribed here: https://angular.io/guide/http#making-a-request-for-json-data
    this.httpService.get('./assets/json/' + this.title + '.json').subscribe(
      data => {
        this.navMenu = data as Array<INavCat>; // fill the array with data
        // register dynamic pages in Angular router
        for (const navCat of this.navMenu) {
          for (const item of navCat.Pages) {
            const navItem: INavItem = item;
            this.registerPage(navItem.Title, this.title.toLowerCase() + '/' + navItem.ContentURL);
          };
        };
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  ngAfterViewInit() {

    // register events for the Navbar Burger Toggle

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active'); // TODO: this seems not to work,
          // but see below event handler for the ".navbar-menu"; this one worked...
        });
      });
    }

    const $navbarMenus = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu'), 0);

    // Check if there are any navbar menus
    if ($navbarMenus.length > 0) {

      // Add a click event on each of them
      $navbarMenus.forEach( el => {
        el.addEventListener('click', () => {

          // Toggle the "is-active" class on the "navbar-menu"
          el.classList.toggle('is-active');

          // Try to (back-)toggle the burger...
          const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

          // Check if there are any navbar burgers
          if ($navbarBurgers.length > 0) {

            // Add a click event on each of them
            $navbarBurgers.forEach( nb => {
              // Get the target from the "data-target" attribute
              if (nb.dataset.target == el.id) {
                // Toggle burger
                nb.classList.toggle('is-active');
              }
            });
          }
        });
      });
    }
  }

  renderPage(section: string, title: string, link: string) {
    // TODO: this is not a page change and rather a component update; don't use router object here, but HOW???
    // this.router.navigate([link]);

    // TODO: get page data in Markdown (from link)
    // ...

    // TODO: works only every second call... why???

    // TODO: read about https://angular.io/guide/http !!!

    // TODO: switch link in address line!
    let url = section.toLowerCase() + '/' + link;
    if (url.substr(url.length - 3, 3) === '.md') {
      // HACK. smells like it...
      url = url.substr(0, url.length - 3);
    }
    if (link.substr(link.length - 3, 3) !== '.md') {
      // HACK. smells like it...
      link = link + '.md';
    }

    this.location.replaceState(url);

    // TODO: replace below URl with a path to a .md documents hosting site, possible in OpenFaaS???!
    this.httpService.get('./assets/md/' + link, {responseType: 'text'})
      .subscribe(
        data => {
          //console.log(data);
          // Convert Markdown data into HTML and assign to page content
          //  this.faasInvoker.invokeFunction('markdown', data as string)
          // TODO: consider using a generic function endpoint like 'markdown' and use the proxy config to rewrite
          //this.faasInvoker.invokeFunction('blackfriday-markdown', data as string)
          //      .subscribe(content => {this.pageContent = content; console.log(content); return content;} );

          // TODO: I deactivated FaaS usage here, not only because the Contabo VM that was running this VM costs money (3,99 € per month),
          // also because such static website doesn't really require a dynamic Function as a Service ...
          // sorry, but see you later OpenFaas...

          // use pre-generated HTML files instead of Faas service to produce them from .md files
          this.httpService.get('./assets/md/' + link + '.html', {responseType: 'text'})
          .subscribe(
            content => {this.pageContent = content; /*console.log('link: ', link); console.log(content);*/ return content;} );
        },
        (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      );

    // Set content title from title arg
    this.pageTitle = title;
  }
}
