import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tiles-page',
  templateUrl: './tiles-page.component.html',
  styleUrls: ['./tiles-page.component.sass']
})
export class TilesPageComponent implements OnInit {

  title = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    // receive 'title' from Angular router data
    this.route.data
    .subscribe((data: { title: string }) => {
      this.title = data.title;
    });
  }

}
